window.designer = {
  init: options => {
    import(/* webpackPrefetch: true */ "../designer/index").then(
      ({ launchInModal }) => {
        launchInModal(options)
      }
    )
  },
  initSimple: options => {
    import(/* webpackPrefetch: true */ "../simple-designer/index").then(
      ({ launchInModal }) => {
        launchInModal(options)
      }
    )
  }
}
